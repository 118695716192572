import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const SiiStatusFilter = ({ filter, setFilter, options }) => (
  <FormControl sx={{ width: '180px' }}>
    <Select
      name="siiStatus"
      value={filter}
      onChange={(event) => setFilter(event.target.value)}
    >
      {Object.entries(options).map(([value, text]) => (
        <MenuItem key={value} value={value}>
          {text}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

SiiStatusFilter.propTypes = {
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  options: PropTypes.objectOf(PropTypes.string),
};

SiiStatusFilter.defaultProps = {
  options: {
    All: 'Estado',
    'Reception acknowledged': 'Con acuse de recibo',
    Rejected: 'Rechazadas',
    Valid: 'Sin acciones en el Sii' },
};

export default SiiStatusFilter;
